import { redirect } from 'next/navigation'
import { SearchType } from '../(site)/search/types'
import { removeWords } from './remove-image-types'
import { safeDecodeURIComponent } from './url'

/*
 * When searching on the site in the search bar we convert spaces to -,
 * but if somehow `/search/happy birthday` is entered in the address bar
 * we will redirect to `/search/happy-birthday
 */
export const whiteSpaceRedirect = (term: string) => {
    let decodedTerm = safeDecodeURIComponent(term)
    if (/.{0,}\s+.?/.test(decodedTerm)) {
        redirect(decodedTerm.replaceAll(' ', '-'))
    }
}

export const processSearchTerm = (searchTerm: string): [string, SearchType] => {
    let term = safeDecodeURIComponent(searchTerm)
    const type: SearchType = getSearchType(term)
    term = removeWords(term).replaceAll('-', ' ').trim()
    return [term, type]
}

export function getSearchType(decodedTerm: string): SearchType {
    return decodedTerm.endsWith('-stickers') ? 'stickers' : decodedTerm.endsWith('-clips') ? 'clips' : 'gifs'
}
