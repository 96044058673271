'use client'

import { createContext } from 'react'

export type AdsContextProps = {
    adsEnabled: boolean
    adUnits: string[]
    isAdUnitEnabled: (unitName: string) => boolean
    pageName?: string
}

const AdsContext = createContext({
    adsEnabled: false,
    adUnits: [],
    isAdUnitEnabled: () => false,
    pageName: undefined,
} as AdsContextProps)

export default AdsContext
