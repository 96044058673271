const imageTypes = ['gif', 'gifs', 'sticker', 'stickers', 'video', 'videos', 'transparent', 'clips']
const search = imageTypes
    .map((type) => {
        return `\\b(${type})\\b`
    })
    .join('|')
const regExp = new RegExp(search, `gi`)

export const removeWords = (fullString = '') => {
    return fullString.replace(regExp, '').trim()
}
