'use client'

import { publicRuntimeConfig } from '@/app/util/env'
import { AdProductContext } from '@/context/ad-product'
import { ChannelContextManager } from '@/context/channel-context'
import { IChannel } from '@giphy/js-types'
import { ReactNode, useContext } from 'react'
import HeaderSearchBar from '../../components/search-bar'
import UAParserContext from '../../context/ua-parser'
import { useGA4 } from '../../util/analytics'
import type { TakeoverData } from '../util/searchbar-takeover'

type Props = {
    children: ReactNode
    countryCode?: string
    takeover?: TakeoverData
    channel?: IChannel
}

const GA4 = () => {
    useGA4()
    return null
}
const HeaderInit = ({ children, takeover, channel }: Props) => {
    const { deviceType } = useContext(UAParserContext)
    return (
        <ChannelContextManager channel={channel}>
            <AdProductContext.Provider value={{ takeover }}>
                <GA4 />
                <HeaderSearchBar
                    apiKey={deviceType === 'mobile' ? publicRuntimeConfig.mobileApiKey : publicRuntimeConfig.apiKey}
                >
                    {children}
                </HeaderSearchBar>
            </AdProductContext.Provider>
        </ChannelContextManager>
    )
}
export default HeaderInit
