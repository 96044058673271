import { IChannel } from '@giphy/js-types'
import { createContext, ReactNode } from 'react'

export const ChannelContext = createContext({
    channel: undefined as IChannel | undefined,
})

export const ChannelContextManager = ({ channel, children }: { children: ReactNode; channel?: IChannel }) => {
    return <ChannelContext.Provider value={{ channel }}>{children}</ChannelContext.Provider>
}
